@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
@import "antd/dist/antd.css";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Poppins", sans-serif;
  /* background-color: #ecf0f3; */
}

.ant-popover-inner-content {
  padding: 5px 5px 0.1px 5px !important;
  background: #1d1335;
  color: aliceblue;
}
.ant-popover-arrow-content {
  --antd-arrow-background-color: #1d1335 !important;
}

*::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
  z-index:0;
}

video::-webkit-media-controls-overlay-play-button {
  display: none !important;
  z-index:0;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
  z-index:0;
}