@media only screen and (max-width: 850px) {
    .normal {
      min-height: 1800px !important;
    }
  
    .zero {
      min-height: 1887px !important;
    }
  
    .one {
      min-height: 1910px !important;
    }
  
    .two {
      min-height: 1937px !important;
    }
  
    .three {
      min-height: 1937px !important;
    }
  
    .four {
      min-height: 1883px !important;
    }
  
    .five {
      min-height: 1937px !important;
    }
  
    .six {
      min-height: 1857px !important;
    }
  
    .seven {
      min-height: 1857px !important;
    }
    .eight {
      min-height: 1883px !important;
    }
    .nine {
      min-height: 1883px !important;
    }
    .ten {
      min-height: 1857px !important;
    }
    .eleven {
      min-height: 1883px !important;
    }
    .twelve {
      min-height: 1857px !important;
    }
    .thirteen {
      min-height: 1910px !important;
    }
    .fourteen {
      min-height: 1910px !important;
    }
    .fifteen {
      min-height: 1883px !important;
    }
  
    .sixteen {
      min-height: 1857px !important;
    }
    .seventeen {
      min-height: 1910px !important ;
    }
    .eighteen {
      min-height: 1990px !important;
    }
  }
  
  @media only screen and (max-width: 785px) {
    .normal {
      min-height: 1800px !important;
    }
  
    .zero {
      min-height: 1910px !important;
    }
  
    .ten {
      min-height: 1883px !important;
    }
  
    .twelve {
      min-height: 1883px !important;
    }
  
    .eighteen {
      min-height: 2017px !important;
    }
  }
  
  @media only screen and (max-width: 765px) {
    .two {
      min-height: 1964px !important;
    }
  }
  
  @media only screen and (max-width: 760px) {
    .one {
      min-height: 1937px !important;
    }
  }
  
  @media only screen and (max-width: 740px) {
    .three {
      min-height: 1963px !important;
    }
  }
  
  @media only screen and (max-width: 725px) {
    .thirteen {
      min-height: 1937px !important;
    }
  
    .fourteen {
      min-height: 1937px !important;
    }
  }
  
  @media only screen and (max-width: 710px) {
    .five {
      min-height: 1964px !important;
    }
  }
  
  @media only screen and (max-width: 695px) {
    .seven {
      min-height: 1883px !important;
    }
  }
  
  @media only screen and (max-width: 695px) {
    .eleven {
      min-height: 1910px !important;
    }
  }
  
  @media only screen and (max-width: 650px) {
    .normal {
      min-height: 1900px !important;
    }
  
    .zero {
      min-height: 2010px !important;
    }
  
    .one {
      min-height: 2037px !important;
    }
  
    .two {
      min-height: 2090px !important;
    }
  
    .three {
      min-height: 2064px !important;
    }
  
    .four {
      min-height: 1983px !important;
    }
  
    .five {
      min-height: 2064px !important;
    }
  
    .six {
      min-height: 1957px !important;
    }
  
    .seven {
      min-height: 1983px !important;
    }
    .eight {
      min-height: 1983px !important;
    }
    .nine {
      min-height: 1983px !important;
    }
    .ten {
      min-height: 1983px !important;
    }
    .eleven {
      min-height: 2010px !important;
    }
    .twelve {
      min-height: 1983px !important;
    }
    .thirteen {
      min-height: 2037px !important;
    }
    .fourteen {
      min-height: 2037px !important;
    }
    .fifteen {
      min-height: 1983px !important;
    }
  
    .sixteen {
      min-height: 1957px !important;
    }
    .seventeen {
      min-height: 2010px !important ;
    }
    .eighteen {
      min-height: 2144px !important;
    }
  }
  
  @media only screen and (max-width: 610px) {
    .eighteen {
      min-height: 2154px !important;
    }
  }
  
  @media only screen and (max-width: 580px) {
    .normal {
      min-height: 2000px !important;
    }
  
    .zero {
      min-height: 2094px !important;
    }
    .one {
      min-height: 2148px !important;
    }
    .two {
      min-height: 2174px !important;
    }
    .three {
      min-height: 2174px !important;
    }
  
    .four {
      min-height: 2067px !important;
    }
    .five {
      min-height: 2148px !important;
    }
  
    .six {
      min-height: 2041px !important;
    }
  
    .seven {
      min-height: 2067px !important;
    }
    .eight {
      min-height: 2067px !important;
    }
    .nine {
      min-height: 2067px !important;
    }
    .ten {
      min-height: 2067px !important;
    }
    .eleven {
      min-height: 2094px !important;
    }
    .twelve {
      min-height: 2067px !important;
    }
    .thirteen {
      min-height: 2121px !important;
    }
    .fourteen {
      min-height: 2148px !important;
    }
    .fifteen {
      min-height: 2067px !important;
    }
  
    .sixteen {
      min-height: 2067px !important;
    }
    .seventeen {
      min-height: 2121px !important ;
    }
  
    .eighteen {
      min-height: 2254px !important;
    }
  }
  
  @media only screen and (max-width: 560px) {
    .six {
      min-height: 2067px !important;
    }
  
    .thirteen {
      min-height: 2148px !important;
    }
  }
  
  @media only screen and (max-width: 540px) {
    .normal {
      min-height: 2050px !important;
    }
  
    .zero {
      min-height: 2144px !important;
    }
    .one {
      min-height: 2198px !important;
    }
  
    .two {
      min-height: 2251px !important;
    }
    .three {
      min-height: 2224px !important;
    }
    .four {
      min-height: 2144px !important;
    }
    .five {
      min-height: 2224px !important;
    }
    .six {
      min-height: 2117px !important;
    }
  
    .seven {
      min-height: 2117px !important;
    }
    .eight {
      min-height: 2144px !important;
    }
    .nine {
      min-height: 2117px !important;
    }
    .ten {
      min-height: 2117px !important;
    }
  
    .eleven {
      min-height: 2144px !important;
    }
  
    .twelve {
      min-height: 2117px !important;
    }
  
    .thirteen {
      min-height: 2198px !important;
    }
  
    .fourteen {
      min-height: 2198px !important;
    }
  
    .fifteen {
      min-height: 2144px !important;
    }
  
    .sixteen {
      min-height: 2117px !important;
    }
  
    .seventeen {
      min-height: 2171px !important;
    }
    .eighteen {
      min-height: 2331px !important;
    }
  }
  
  @media only screen and (max-width: 535px) {
    .zero {
      min-height: 2171px !important;
    }
    .eighteen {
      min-height: 2358px !important;
    }
  }
  
  @media only screen and (max-width: 525px) {
    .three {
      min-height: 2251px !important;
    }
  }
  
  @media only screen and (max-width: 510px) {
    .one {
      min-height: 2224px !important;
    }
  }
  
  @media only screen and (max-width: 500px) {
    .five {
      min-height: 2251px !important;
    }
  }
  
  @media only screen and (max-width: 490px) {
    .two {
      min-height: 2278px !important;
    }
  }
  
  @media only screen and (max-width: 470px) {
    .nine {
      min-height: 2144px !important;
    }
    .eleven {
      min-height: 2171px !important;
    }
  
    .thirteen {
      min-height: 2224px !important;
    }
  }
  
  @media only screen and (max-width: 460px) {
    .three {
      min-height: 2278px !important;
    }
    .ten {
      min-height: 2144px !important;
    }
  }
  
  @media only screen and (max-width: 450px) {
    .two {
      min-height: 2304px !important;
    }
  }
  
  @media only screen and (max-width: 440px) {
    .normal {
      min-height: 1800px !important;
    }
  
    .zero {
      min-height: 1915px !important;
    }
  
    .one {
      min-height: 1965px !important;
    }
  
    .two {
      min-height: 2015px !important;
    }
  
    .three {
      min-height: 2015px !important;
    }
    .four {
      min-height: 1889px !important;
    }
    .five {
      min-height: 1990px !important;
    }
    .six {
      min-height: 1864px !important;
    }
    .seven {
      min-height: 1864px !important;
    }
  
    .eight {
      min-height: 1889px !important;
    }
    .nine {
      min-height: 1889px !important;
    }
    .ten {
      min-height: 1889px !important;
    }
  
    .eleven {
      min-height: 1915px !important;
    }
    .twelve {
      min-height: 1864px !important;
    }
  
    .thirteen {
      min-height: 1965px !important;
    }
    .fourteen {
      min-height: 1965px !important;
    }
  
    .fifteen {
      min-height: 1889px !important;
    }
    .sixteen {
      min-height: 1864px !important;
    }
    .seventeen {
      min-height: 1940px !important;
    }
  
    .eighteen {
      min-height: 2116px !important;
    }
  }
  
  @media only screen and (max-width: 430px) {
    .two {
      min-height: 2040px !important;
    }
  
    .eighteen {
      min-height: 2141px !important;
    }
  }
  
  @media only screen and (max-width: 400px) {
    .twelve {
      min-height: 1889px !important;
    }
  }
  
  @media only screen and (max-width: 394px) {
    .zero {
      min-height: 1940px !important;
    }
  
    .three {
      min-height: 2040px !important;
    }
  
    .five {
      min-height: 2015px !important;
    }
  
    .eighteen {
      min-height: 2166px !important;
    }
  }
  